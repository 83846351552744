import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocaleMessage from "vee-validate/dist/locale/en";
import viLocaleMessage from "vee-validate/dist/locale/vi";
import english from "@/plugins/i18n/en";
import vietnamese from "@/plugins/i18n/vi";
import dictionary from "@/plugins/i18n/dictionary";
import { LOCALE } from "@/enums/locale.enum";
import { localize } from "vee-validate";

Vue.use(VueI18n);

const messages = {
  vi: {
    ...vietnamese,
    validation: { ...viLocaleMessage.messages, ...dictionary.vi },
  },
  en: {
    ...english,
    validation: { ...enLocaleMessage.messages, ...dictionary.en },
  },
};

const i18n = new VueI18n({
  locale: LOCALE.en, // set locale
  fallbackLocale: LOCALE.en, // set fallback locale
  silentTranslationWarn: true,
  messages, // set locale messages
});

// Install English and Arabic localizations.
localize(messages);

export default i18n;
