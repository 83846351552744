import {UserRegisteredBrokerService} from "@/services";

const state = {
    userRegisteredBrokers: [],
    userRegisteredBroker: {},
    userRegisteredBrokersTemp: []
}

const mutations = {
    SET_USER_REGISTERED_BROKERS(state, userRegisteredBrokers) {
        state.userRegisteredBrokers = userRegisteredBrokers
    },
    SET_USER_REGISTERED_BROKER(state, userRegisteredBroker) {
        state.userRegisteredBroker = userRegisteredBroker
    },
    SET_USER_REGISTERED_BROKERS_TEMP(state, userRegisteredBrokersTemp) {
        state.userRegisteredBrokersTemp = userRegisteredBrokersTemp
    },
}

const actions = {
    async fetchUserRegisteredBrokers({commit}, params = {}) {
        const res = await UserRegisteredBrokerService.getList(params)
        commit('SET_USER_REGISTERED_BROKERS', res.data)
        commit('SET_USER_REGISTERED_BROKERS_TEMP', res.data)
    },

    async fetchUserRegisteredBroker({commit}, params = {}) {
        const { id } = params;
        delete params.id;

        const res = await UserRegisteredBrokerService.getItem(id, { params })
        commit('SET_USER_REGISTERED_BROKER', res.data)

        return res;
    },

    async register(_, params = {}) {
        return await UserRegisteredBrokerService.postUserRegisteredBroker(params);
    },

    async postSendMail(_, params = {}) {
        const { id } = params;
        delete params.id;
        const res = await UserRegisteredBrokerService.postSendMail(id, { params })

        return res
    },
}

const getters = {
    getUserRegisteredBrokers: state => state.userRegisteredBrokers,
    getUserRegisteredBroker: state => state.userRegisteredBroker,
    getUserRegisteredBrokersTemp: state => state.userRegisteredBrokersTemp,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
