<template>
  <div class="withdraw-detail-modal">
    <div class="modal-header">
      <h5 class="mb-0">Withdraw</h5>
      <button type="button" class="btn-close btn-close-white" @click="closeModal"></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-md-12 col-xl-12 mx-auto">

          <div class="block-info-withdraw">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C12.5523 1 13 1.44772 13 2V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V2C11 1.44772 11.4477 1 12 1Z" fill="#9F9F9F"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19C12.5523 19 13 19.4477 13 20V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V20C11 19.4477 11.4477 19 12 19Z" fill="#9F9F9F"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7C5 4.79086 6.79086 3 9 3H15C17.2091 3 19 4.79086 19 7V8C19 8.55228 18.5523 9 18 9C17.4477 9 17 8.55228 17 8V7C17 5.89543 16.1046 5 15 5H9C7.89543 5 7 5.89543 7 7V7.11696C7 8.40825 7.82629 9.55467 9.05132 9.96301L15.5811 12.1396C17.6228 12.8202 19 14.7309 19 16.883V17C19 19.2091 17.2091 21 15 21H9C6.79086 21 5 19.2091 5 17V16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16V17C7 18.1046 7.89543 19 9 19H15C16.1046 19 17 18.1046 17 17V16.883C17 15.5917 16.1737 14.4453 14.9487 14.037L8.41886 11.8604C6.37715 11.1798 5 9.26912 5 7.11696V7Z" fill="#F2C94C"/>
            </svg>
            <p>Currency of transfer: VND</p>
          </div>

          <div class="block-info-withdraw">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9ZM7 11C9.20914 11 11 9.20914 11 7C11 4.79086 9.20914 3 7 3C4.79086 3 3 4.79086 3 7C3 9.20914 4.79086 11 7 11Z" fill="#9F9F9F"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17C15 18.1046 15.8954 19 17 19ZM17 21C19.2091 21 21 19.2091 21 17C21 14.7909 19.2091 13 17 13C14.7909 13 13 14.7909 13 17C13 19.2091 14.7909 21 17 21Z" fill="#9F9F9F"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6001 3.2C19.0419 3.53137 19.1314 4.15817 18.8001 4.6L6.80006 20.6C6.46869 21.0418 5.84189 21.1314 5.40006 20.8C4.95823 20.4686 4.86869 19.8418 5.20006 19.4L17.2001 3.4C17.5314 2.95817 18.1582 2.86863 18.6001 3.2Z" fill="#F2C94C"/>
            </svg>
            <p>Conversion Rate USD/VND: {{ this.getRatioVND }}</p>
          </div>

          <hr />

          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(handleSubmitWithdraw)">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Bank name: </label>
                    <input
                        v-model="formData.bank_name"
                        type="text"
                        class="form-control"
                        :disabled="true"
                    >
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Bank account name: </label>
                    <input
                        v-model="formData.bank_account_name"
                        type="text"
                        class="form-control"
                        :disabled="true"
                        value="TRC20"
                    >
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Bank account number: </label>
                    <input
                        v-model="formData.bank_account_number"
                        type="text"
                        class="form-control"
                        :disabled="true"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <ValidationProvider
                      mode="lazy"
                      name="Money"
                      :rules="`required|money:1,${ getStatistics.total_money_withdraw * getRatioVND }`"
                      v-slot="{ errors }"
                  >
                    <div class="mb-3">
                      <label class="form-label">Withdraw Amount (VND): </label>
                      <input
                          ref="money"
                          type="text"
                          v-model="formData.money"
                          class="form-control"
                          @keyup="handleChangeVNDToUSD($event)"
                          :class="{ 'is-invalid': errors[0] }"
                      >
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-12 col-md-6">
                  <ValidationProvider
                      mode="lazy"
                      name="Money"
                      :rules="`required|money:1,${getStatistics.total_money_withdraw}`"
                      v-slot="{ errors }"
                  >
                    <div class="mb-3">
                      <label class="form-label">Withdraw Amount (USD): </label>
                      <input
                          type="text"
                          v-model="formData.money_usd"
                          class="form-control"
                          @keyup="handleChangeUSDToVND($event)"
                          :class="{ 'is-invalid': errors[0] }"
                      >
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <ValidationProvider name="code otp" rules="required" v-slot="{ errors }">
                <div class="mb-3 row">
                  <div class="col-6 col-md-8">
                    <label class="form-label">Code OTP: </label>
                    <input
                        type="text"
                        v-model="formData.code_otp"
                        class="form-control"
                        :class="{ 'is-invalid': errors[0] }"
                    >
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </div>
                  <div class="col-6 col-md-4">
                    <button
                        style="margin-top: 29px"
                        type="button"
                        class="btn btn-outline btn--custom btn--otp"
                        :disabled="counter < 30"
                        @click="handleGetOTP()"
                    >
                      Get OTP {{ counter < 30 ? '(' + counter + 's)' : '' }}
                    </button>
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider name="policy" :rules="{ is: !formData.policy }" v-slot="{ errors }">
                <div class="form-group form-check mb-3">
                  <input
                      type="checkbox"
                      class="form-check-input"
                      :class="{ 'is-invalid': errors[0] }"
                      v-model="formData.policy"
                  >
                  <label class="form-check-label">
                    I declare that I have carefully read and fully understood the <a href="">Withdraw Priority
                    Procedure</a> of XM, which I accept and agree with, and that I understand.
                  </label>
                  <div class="invalid-feedback">
                    Please select terms and conditions.
                  </div>
                </div>
              </ValidationProvider>

              <button type="submit" class="btn btn--custom btn--submit">Withdraw</button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";

export default {
  name: 'WithdrawModal',

  components: {},

  props: {
    data: {
      type: [Object, String],
      default: ''
    }
  },

  computed: {
    ...mapGetters('billingInformation', ['getBankInformation']),
    ...mapGetters('conversions', ['getConversions']),
    ...mapGetters('crm', ['getStatistics']),

    getRatioVND() {
      return this.getConversions.find((data) => data.conversion_name === 'VND')?.ratio
    }
  },

  data() {
    return {
      counter: 30,
      counterInterval: null,
      formData: {
        id: '',
        method: 'BANK',
        code_otp: '',
        money: 0,
        money_usd: 0,
        policy: false,
        bank_name: '',
        bank_account_number: '',
        bank_account_name: '',
      }
    }
  },

  async created() {
    const sessionStorage = localStorage.getItem('COUNTER');

    if (sessionStorage) {
      this.counter = parseInt(sessionStorage);

      if (!this.counter || this.counter > 30) {
        this.counter = 30;
      }

      this.counterInterval = setInterval(() => {
        this.handleCounter();
      }, 1000);
    }

    await this.fetchBankInformation();
    await this.fetchConversions();
    await this.fetchStatistics();

    if (this.getBankInformation) {
      this.formData.id = this.getBankInformation?.id;
      this.formData.bank_name = this.getBankInformation?.bank_name;
      this.formData.bank_account_number = this.getBankInformation?.bank_account_number;
      this.formData.bank_account_name = this.getBankInformation?.bank_account_name;
    }
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('billingInformation', ['sendVerifyCode', 'fetchBankInformation']),
    ...mapActions('crm', ['postWithdrawMoney', 'fetchStatistics', 'fetchWithdrawMoney']),
    ...mapActions('conversions', ['fetchConversions']),

    async handleGetOTP() {
      if (this.counter < 30) {
        return;
      }

      try {
        clearInterval(this.counterInterval);
        this.counterInterval = setInterval(() => {
          this.handleCounter();
        }, 1000);

        await this.sendVerifyCode({resource: "WITHDRAW_MONEY"});

        Vue.$toast.open({
          message: 'Gửi mã OTP thành công. Vui lòng kiểm mail!',
          position: 'top-right',
          duration: 3000,
        });
      } catch (e) {
        clearInterval(this.counterInterval);
      }
    },

    async handleSubmitWithdraw() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        try {
          const formData = {
            money: this.formData.money_usd,
            method: this.formData.method,
            code_otp: this.formData.code_otp
          }

          await this.postWithdrawMoney(formData);

          Vue.$toast.open({
            message: 'Rút tiền thành công!',
            position: 'top-right',
            duration: 3000,
          });

          await this.fetchWithdrawMoney();
          await this.fetchStatistics();
          await this.closeModal();
        } catch (e) {
          if (e.response.status === 400) {
            Vue.$toast.open({
              type: 'error',
              message: e.response.data.error.message || '',
              position: 'top-right',
              duration: 3000,
            });
          }
        }
      }
    },

    handleCounter() {
      this.counter--;
      localStorage.setItem('COUNTER', this.counter);

      if (this.counter <= 0) {
        this.counter = 30;
        localStorage.removeItem('COUNTER');
        clearInterval(this.counterInterval)
      }
    },

    handleChangeVNDToUSD($event) {
      this.formData.money_usd = Math.round(($event.target.value / this.getRatioVND) * 1000) / 1000;
    },

    handleChangeUSDToVND($event) {
      this.formData.money = Math.round(($event.target.value * this.getRatioVND) * 1000) / 1000;
    }
  },
}
</script>
<style lang="scss" scoped>
.modal-body {
  padding: 30px;
}

.btn.btn {
  &--otp {
    height: 48px;
    width: 100%;
    min-width: unset;
  }

  &--submit {
    height: 50px;
    width: 100%;
    max-width: 100%;
  }
}

.withdraw-detail-modal {
  max-width: 600px;
}

.block-info-withdraw {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  p {
    margin-bottom: 0;
  }
}
</style>
