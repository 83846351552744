import baseService from "@/services/base.service";

const baseUrl = "brokers";
export const BrokerService = {
  getList(params = {}) {
    return baseService.get(`${baseUrl}`, params).then((resp) => {
      return resp.data;
    });
  },

  getListNoPaginate(params = {}) {
    return baseService.get(`${baseUrl}/list`, params).then((resp) => {
      return resp.data;
    });
  },

  getItem(id, config = {}) {
    return baseService.get(`${baseUrl}/${id}`, config).then((resp) => {
      return resp.data;
    });
  },
};
