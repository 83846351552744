export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const BrokerRoute = {
  path: "/broker",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "BrokerPage",
      component: Page("HomePage"),
    },
    {
      path: "/broker/:id",
      name: "BrokerDetailPage",
      component: Page("BrokerDetailPage"),
    },
  ],
};

export default BrokerRoute;
