<template>
  <div>
    <h1>401 Unauthorized</h1>
    <p>{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "UnauthorizedPage",

  data() {
    return {
      errorMessage: "Invalid request. Please check your input.",
    };
  },
};
</script>
