import store from "@/store";
import {LOCAL_STORAGE} from "@/enums";

export const AuthGuard = async (to, from, next) => {
  const isAuthenticated = localStorage.getItem(LOCAL_STORAGE.USER_TOKEN);

  if (!isAuthenticated) {
    next({ name: "LoginPage" });
    return;
  }

  if (!store.getters["auth/currentUser"]) {
    await store.dispatch('auth/fetchProfile')
  }

  next();
};
