import baseService from "@/services/base.service";

const baseUrl = "posts";
export const PostService = {
  getList(params = {}) {
    return baseService.get(`${baseUrl}`, { params }).then((resp) => {
      return resp.data;
    });
  },

  getItem(id, config = {}) {
    return baseService.get(`${baseUrl}/${id}`, config).then((resp) => {
      return resp.data;
    });
  },
};
