import {PostService} from "@/services";

const state = {
  posts: [],
  post: {}
}

const mutations = {
  SET_POSTS(state, posts) {
    state.posts = posts
  },
  SET_POST(state, post) {
    state.post = post
  },
}

const actions = {
  async fetchPosts({commit}, params) {
    const res = await PostService.getList(params)
    commit('SET_POSTS', res.data)
  },

  async fetchPost({commit}, params = {}) {
    const { id } = params;
    const res = await PostService.getItem(id, params)
    commit('SET_POST', res.data)
  },
}

const getters = {
  getPosts: state => state.posts,
  getPost: state => state.post,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
