export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const BonusHunterRoute = {
  path: "/bonus-hunter",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "BonusHunterPage",
      component: Page("BonusHunterPage"),
    },

    {
      path: "/bonus-hunter/:id",
      name: "BonusHunterDetailPage",
      component: Page("BonusHunterDetailPage"),
    },
  ],
};

export default BonusHunterRoute;
