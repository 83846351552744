import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "@/store";
import VueToast from 'vue-toast-notification';
import filters from './filters'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.config.productionTip = false
import 'vue-toast-notification/dist/theme-bootstrap.css';

Vue.use(VueToast);

// Plugins
import "@/plugins/vee-validate";
import "@/plugins/lodash";
import "@/plugins/moment";
import i18n from "@/plugins/i18n";

import "@/assets/scss/main.scss";

import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';

new Vue({
  i18n,
  router, // Use the refactored router
  store,
  filters,
  render: h => h(App),
}).$mount('#app')
