import {AuthService} from "@/services";
import {LOCAL_STORAGE} from "@/enums/localStorage.enum";

const state = {
  profile: {},
  isAuthenticated: !!localStorage.getItem(LOCAL_STORAGE.USER_TOKEN),
}

const mutations = {
  SET_AUTH(state, profile) {
    state.profile = profile
  },
  SET_LOGOUT(state) {
    state.isAuthenticated = false;
    state.currentUser = {};
    localStorage.removeItem(LOCAL_STORAGE.USER_TOKEN);
  },
}

const actions = {
  async fetchProfile({commit}) {
    const res = await AuthService.profile()
    commit('SET_AUTH', res.data)
  },

  async handleLogin({commit, state}, params = {}) {
    const {data} = await AuthService.login(params);
    if (data?.token) {
      localStorage.setItem(LOCAL_STORAGE.USER_TOKEN, data.token)
      state.isAuthenticated = true;
    }
    if (data?.profile) {
      commit("SET_AUTH", data.profile);
    }

    return data;
  },

  async handleRegister(_, params = {}) {
    const {data} = await AuthService.register(params);
    return data;
  },

  async handleLogout({commit}) {
    await AuthService.logout();
    commit("SET_LOGOUT");
  },

  async updateProfile(_, params = {}) {
    await AuthService.updateProfile(params)
  }
}

const getters = {
  getProfile: state => state.profile,
  isAuthenticated: state => state.isAuthenticated,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
