<template>
  <div class="market-detail-modal">
    <div class="modal-header">
      <h5 class="mb-0">Join broker?</h5>
      <button type="button" class="btn-close btn-close-white" @click="closeModal"></button>
    </div>
    <div class="modal-body">
      <form-wizard @onComplete="onComplete">
        <tab-content class="step-1" title="Step 1" :selected="true">
          <div class="d-flex flex-column align-items-center my-3">
            <button class="btn btn-secondary mb-3">Rebate to My Wallet</button>
            <button class="btn btn-secondary mb-3">Pair Direct to Your Broker Account</button>
          </div>
        </tab-content>
        <tab-content title="Step 2">
          <div class="form-group my-3">
            <select v-model="formData.new_key" name="new_key" id="new_key" class="form-control"
                    :class="hasError('new_key') ? 'is-invalid' : ''">
              <option value="">Please select</option>
              <option value="standard">Standard</option>
              <option value="standard-1">Standard 1</option>
              <option value="standard-2">Standard 2</option>
              <option value="standard-3">Standard 3</option>
            </select>

            <div v-if="hasError('new_key')" class="invalid-feedback">
              <div
                  class="error"
                  v-if="!$v.formData.new_key.required"
              >Please provide a valid company name.
              </div>
            </div>
          </div>

          <p class="mb-3">Please select your preferred option for rebates</p>
          <div class="form-group mb-3">
            <div class="form-check">
              <input v-model="formData.rebate_method" class="form-check-input" type="radio" name="flexRadioDefault"
                     id="flexRadioDefault1" value="ONLY_HISTORIES">
              <label class="form-check-label" for="flexRadioDefault1">
                Direct to broker account (Daily)
              </label>
            </div>
            <div class="form-check">
              <input v-model="formData.rebate_method" class="form-check-input" type="radio" name="flexRadioDefault"
                     id="flexRadioDefault2" value="SYSTEM" checked>
              <label class="form-check-label" for="flexRadioDefault2">
                Rebate to My Wallet
              </label>
            </div>

            <div v-if="hasError('rebate_method')" class="invalid-feedback">
              <div
                  class="error"
                  v-if="!$v.formData.rebate_method.required"
              >Please provide a valid company name.
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Step 3">
          <div class="form-group mb-3">
            <label for="code_registered">Code</label>
            <input
                :class="hasError('code_registered') ? 'is-invalid' : ''"
                class="form-control"
                v-model="formData.code_registered"
            />
            <div v-if="hasError('code_registered')" class="invalid-feedback">
              <div class="error" v-if="!$v.formData.code_registered.required">Please select on of the fields.</div>
            </div>
          </div>
          <div class="form-group form-check">
            <input
                type="checkbox"
                :class="hasError('policy') ? 'is-invalid' : ''"
                class="form-check-input"
                v-model="formData.policy"
            >
            <label class="form-check-label">I accpet terms & conditions</label>
            <div v-if="hasError('policy')" class="invalid-feedback">
              <div class="error" v-if="!$v.formData.policy.checked">Please select terms and conditions.</div>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import {FormWizard, TabContent, ValidationHelper} from "vue-step-wizard";
import {required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
//local registration
import 'vue-step-wizard/dist/vue-step-wizard.css'
import Vue from "vue";

export default {
  name: 'MarketJoinModal',

  components: {
    FormWizard,
    TabContent
  },
  mixins: [ValidationHelper],
  props: {
    data: {
      type: [Object, String],
      default: ''
    }
  },

  data() {
    return {
      formData: {
        rebate_method: 'SYSTEM',
        new_key: null,
        code_registered: null,
        policy: false
      },
      validationRules: [
        {},
        {rebate_method: {required}, new_key: {required}},
        {code_registered: {required}, policy: { checked: value => value === true }}
      ],
    }
  },

  methods: {
    ...mapActions('modal', ['closeModal', 'openModal']),
    ...mapActions('userRegisteredBroker', ['register']),

    async onComplete() {

      try {
        const id = this.$route.params.id;
        delete this.formData.new_key;
        delete this.formData.policy;
        const res = await this.register({ broker_id: id, ...this.formData })

        Vue.$toast.open({
          message: 'Tham gia thành công!',
          position: 'top-right',
          duration: 3000,
        });

        if (res.data) {
          await this.$router.push({ name: 'SuccessMarketSendMailPage', params: { id, code: res.data.id }})
          await this.closeModal();
          this.openModal({component: 'SuccessMarketJoinModal', data: {}})
        }

      } catch (err) {
        if (err.response.status === 422 && err.response.data?.errors?.[0]?.message.length > 0) {
          Vue.$toast.open({
            type: 'error',
            message: err.response.data.errors[0].message?.[0],
            position: 'top-right',
            duration: 3000,
          });
        } else {
          Vue.$toast.open({
            type: 'error',
            message: 'Tham gia thất bại',
            position: 'top-right',
            duration: 3000,
          });
        }
      }
    },
  },
}
</script>
<style lang="scss">
.card {
  background-color: unset !important;

  .card-header {
    @media only screen and (max-width: 768px) {
      padding: 0;
      margin-bottom: 15px;
    }
  }

  .card-body {
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .tabStatus {
    @media only screen and (max-width: 768px) {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      line-height: 20px;
      font-size: 12px;
    }
  }

  .nav-pills {
    @media only screen and (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }

  .nav-pills.nav-wizard > li a {
    background-color: #2c333b;

    @media only screen and (max-width: 768px) {
      border-radius: 4px;
      padding: 5px 10px;
      font-size: 14px;
    }
  }

  .nav-pills.nav-wizard>li {
    border-left: 0;
    border-right: 8px solid transparent;
    flex: unset;

    &:last-child {
      border-right: 0;
    }
  }

  .nav-fill > .nav-link, .nav-fill .nav-item {
    @media only screen and (max-width: 768px) {
      margin: 0 !important;
    }
  }

  .nav-pills.nav-wizard > li a:before {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .nav-pills.nav-wizard > li a:after {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .nav-pills.nav-wizard > li:not(:first-child) a:before {
    border-color: #2c333b #2c333b #2c333b transparent;
  }

  .nav-pills.nav-wizard > li:not(:last-child) a:after {
    border-color: transparent transparent transparent #2c333b;
  }

  .nav-pills.nav-wizard > li.active a {
    background-color: #F8AF2F;
  }

  .nav-pills.nav-wizard > li.active:not(:first-child) a:before {
    border-color: #F8AF2F #F8AF2F #F8AF2F transparent;
  }

  .nav-pills.nav-wizard > li.active:not(:last-child) a:after {
    border-color: transparent transparent transparent #F8AF2F;
  }

  .btn-group {
    width: 100%;
    justify-content: center;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F8AF2F;
      color: #FFFFFF;
      border-radius: 50px !important;
      max-width: 200px;
      font-weight: 500;
      width: 100%;
      height: 44px;
      transition: all 0.2s ease-in-out;
    }

    .btn-warning {
      margin-right: 16px;
      background-color: unset;
    }

    .btn-primary {
      margin-right: 16px;
      background-color: #F8AF2F;
      border-color: #F8AF2F;
    }
  }
}

.step-1 {
  .btn {
    width: 320px;

    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
