<template>
  <div v-if="data" class="market-detail-modal">
    <div class="modal-header">
      <h5 class="mb-0">Add Wallet</h5>
      <button type="button" class="btn-close btn-close-white" @click="closeModal"></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-md-12 col-xl-12 mx-auto">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(handleSubmitWallet)">
              <ValidationProvider
                  name="coin account number"
                  :rules="form.type === 'COIN' ? 'required' : ''"
                  v-slot="{ errors }"
              >
                <div class="mb-3">
                  <label class="form-label">Địa chỉ ví: </label>
                  <input
                      type="text"
                      v-model="form.coin_account_number"
                      class="form-control"
                      :class="{ 'is-invalid': errors[0] }"
                  >
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>

              <div class="mb-3">
                <label class="form-label">Mạng lưới: </label>
                <input
                    type="text"
                    class="form-control"
                    value="TRC20"
                    disabled
                >
              </div>

              <ValidationProvider name="code otp" rules="required" v-slot="{ errors }">
                <div class="mb-3 row">
                  <div class="col-6 col-md-8">
                    <label class="form-label">Code OTP: </label>
                    <input
                        type="text"
                        v-model="form.code_otp"
                        class="form-control"
                        :class="{ 'is-invalid': errors[0] }"
                    >
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </div>
                  <div class="col-6 col-md-4">
                    <button
                        style="margin-top: 29px"
                        type="button"
                        class="btn btn-outline btn--custom btn--otp"
                        :disabled="counter < 30"
                        @click="handleGetOTP()"
                    >
                      Get OTP {{ counter < 30 ? '(' + counter + 's)' : '' }}
                    </button>
                  </div>
                </div>
              </ValidationProvider>

              <button type="submit" class="btn btn--custom btn--submit">Lưu</button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";

export default {
  name: 'AddCoinWalletModal',

  components: {},

  props: {
    data: {
      type: [Object, String],
      default: ''
    }
  },

  computed: {
    ...mapGetters('billingInformation', ['getBillingInformation']),

    form() {
      const newForm = {
        type: 'COIN',
        code_otp: '',
        coin_account_number: '',
      }

      if (this.data?.billingInformation) {
        newForm.id = this.data?.billingInformation?.id;
        newForm.coin_account_number = this.data?.billingInformation?.coin_account_number;
      }

      return newForm
    }
  },

  data() {
    return {
      counter: 30,
      counterInterval: null,
    }
  },

  created() {
    const sessionStorage = localStorage.getItem('COUNTER');

    if (sessionStorage) {
      this.counter = parseInt(sessionStorage);

      if (!this.counter || this.counter > 30) {
        this.counter = 30;
      }

      this.counterInterval = setInterval(() => {
        this.handleCounter();
      }, 1000);
    }
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('billingInformation', ['sendVerifyCode', 'postCoinInformation', 'putCoinInformation', 'fetchCoinInformation']),

    async handleGetOTP() {
      if (this.counter < 30) {
        return;
      }

      const resource = this.form.id ? 'UPDATE_COIN_INFORMATION' : 'CREATE_COIN_INFORMATION';

      try {
        clearInterval(this.counterInterval);
        this.counterInterval = setInterval(() => {
          this.handleCounter();
        }, 1000);

        await this.sendVerifyCode({resource});

        Vue.$toast.open({
          message: 'Gửi mã OTP thành công. Vui lòng kiểm mail!',
          position: 'top-right',
          duration: 3000,
        });
      } catch (e) {
        clearInterval(this.counterInterval);
      }
    },

    async handleSubmitWallet() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        try {
          if (this.form.id) {
            await this.putCoinInformation(this.form);

            Vue.$toast.open({
              message: 'Cập nhật Wallet thành công!',
              position: 'top-right',
              duration: 3000,
            });
          } else {
            await this.postCoinInformation(this.form);

            Vue.$toast.open({
              message: 'Thêm Wallet thành công!',
              position: 'top-right',
              duration: 3000,
            });
          }

          await this.fetchCoinInformation();
          await this.closeModal();
        } catch (e) {
          if (e.response.status === 400) {
            Vue.$toast.open({
              type: 'error',
              message: e.response.data.error.message || '',
              position: 'top-right',
              duration: 3000,
            });
          }
        }
      }
    },

    handleCounter() {
      this.counter--;
      localStorage.setItem('COUNTER', this.counter);

      if (this.counter <= 0) {
        this.counter = 30;
        localStorage.removeItem('COUNTER');
        clearInterval(this.counterInterval)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.modal-body {
  padding: 30px;
}

.btn.btn {
  &--otp {
    height: 48px;
    width: 100%;
    min-width: unset;
  }

  &--submit {
    height: 50px;
    width: 100%;
    max-width: 100%;
  }
}
</style>
