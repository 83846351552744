// router.js
import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.VUE_APP_BASE,
  mode: "history",
  routes,
  // linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
