import {LOCAL_STORAGE} from "@/enums";

export const CallbackGuard = async (to, from, next) => {
    const token = to?.query?.token || undefined;

    if (token) {
        localStorage.setItem(LOCAL_STORAGE.USER_TOKEN, token)
        return next({name: "BrokerPage"});
    }

    next({name: "LoginPage"});
};
