export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const PostRoute = {
  path: "/post",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "PostPage",
      component: Page("PostPage"),
    },

    {
      path: "/post/:id",
      name: "PostDetailPage",
      component: Page("PostDetailPage"),
    },
  ],
};

export default PostRoute;
