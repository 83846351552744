import baseService from "@/services/base.service";

export const CrmService = {
    getTopCampaign(params = {}) {
        return baseService.get(`crm-top-campaign`, {params}).then((resp) => {
            return resp.data;
        });
    },

    getStatistic(params = {}) {
        return baseService.get(`crm-statistic`, {params}).then((resp) => {
            return resp.data;
        });
    },

    getHistories(params = {}) {
        return baseService.get(`histories`, {params}).then((resp) => {
            return resp.data;
        });
    },

    getWithdrawMoney(params = {}) {
        return baseService.get(`withdraw-money`, {params}).then((resp) => {
            return resp.data;
        });
    },

    postWithdrawMoney(data = {}) {
        return baseService.post(`withdraw-money`, data).then((resp) => {
            return resp.data;
        });
    },
};
