<template>
  <div>
    <h1>404 Not found</h1>
    <p>{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",

  data() {
    return {
      errorMessage: "Invalid request. Please check your input.",
    };
  },
};
</script>
