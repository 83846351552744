import {BrokerService} from "@/services";

const state = {
  brokers: [],
  brokersNoPaginate: [],
  broker: {}
}

const mutations = {
  SET_BROKERS(state, brokers) {
    state.brokers = brokers
  },
  SET_BROKERS_NO_PAGINATE(state, brokersNoPaginate) {
    state.brokersNoPaginate = brokersNoPaginate
  },
  SET_BROKER(state, broker) {
    state.broker = broker
  },
}

const actions = {
  async fetchBrokers({commit}, params) {
    const res = await BrokerService.getList(params)
    commit('SET_BROKERS', res.data)
  },

  async fetchBrokersNoPaginate({commit}) {
    const res = await BrokerService.getListNoPaginate()
    commit('SET_BROKERS_NO_PAGINATE', res.data)
  },

  async fetchBroker({commit}, params = {}) {
    const { id } = params;
    delete params.id;
    const res = await BrokerService.getItem(id, { params })
    commit('SET_BROKER', res.data)
  },
}

const getters = {
  getBrokers: state => state.brokers,
  getBrokersNoPaginate: state => state.brokersNoPaginate,
  getBroker: state => state.broker,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
