import axios from 'axios';
import {handleErrorResponse, handleSuccessResponse} from "@/utils/responseHandlers";
import store from "@/store";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        store.commit("setLoading", true);

        const token = localStorage.getItem("user_token");
        if (token) config.headers.Authorization = "Bearer " + token;

        if (config.data instanceof FormData) {
            config.headers["Content-Type"] = "multipart/form-data";
        } else {
            config.headers["Content-Type"] = "application/json";
        }
        // Perform any modification to the request config here
        // For example, you can add authentication headers
        // or modify the URL before sending the request
        return config;
    },
    (error) => {
        store.commit("setLoading", false);
        // Handle request errors
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        store.commit("setLoading", false);
        // Handle successful responses globally
        handleSuccessResponse(response); // Call the handleSuccessResponse function
        return response;
    },
    (error) => {
        store.commit("setLoading", false);
        // Handle response errors globally
        handleErrorResponse(error); // Call the handleErrorResponse function
        return Promise.reject(error);
    }
);

export default instance;
