import baseService from "@/services/base.service";

const baseUrl = "auth";
export const AuthService = {
  profile(params = {}) {
    return baseService.get(`${baseUrl}/profile`, params).then((resp) => {
      return resp.data;
    });
  },

  login(data = {}) {
    return baseService.post(`${baseUrl}/login`, data).then((resp) => {
      return resp.data;
    });
  },

  register(data = {}) {
    return baseService.post(`${baseUrl}/register`, data).then((resp) => {
      return resp.data;
    });
  },

  updateProfile(data = {}) {
    return baseService.post(`${baseUrl}/update?_method=PUT`, data).then((resp) => {
        return resp.data;
    });
  },

  logout() {
    return baseService.delete(`${baseUrl}/logout`).then((resp) => {
      return resp.data;
    });
  }
};
