import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend, configure } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import * as messages from "vee-validate/dist/locale/en.json";
import i18n from "@/plugins/i18n";
import "./validator";

Object.entries(rules).forEach(([rule, validation]) => {
  extend(rule, {
    ...validation,
    message: messages[rule],
  });
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  },
});
