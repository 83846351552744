<template>
  <div class="market-detail-modal">
    <div class="modal-header">
      <h5 class="mb-0">Đã thêm tài khoản</h5>
      <button type="button" class="btn-close btn-close-white" @click="closeModal"></button>
    </div>
    <div class="modal-body">
      <p>Congratulations! Your account was added successfully.</p>
      <button class="btn btn--custom mx-auto" @click="closeModal">Chấp nhận</button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'SuccessMarketJoinModal',

  props: {
    data: {
      type: [Object, String],
      default: ''
    }
  },

  data() {
    return {}
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
  },
}
</script>
<style lang="scss">
</style>
