import {AffiliateService} from "@/services";

const state = {}

const mutations = {}

const actions = {
  async countAffiliate(_, params = {}) {
    const res = await AffiliateService.countAffiliate(params);
    console.log(res);
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
