import Vue from 'vue'
import moment from "moment";

const formatMoney = Vue.filter('formatMoney', function (money) {
    return Math.round(money * 100) / 100
})

const formatDate = Vue.filter('formatDate', function (date) {
    return moment(date).format('YYYY-MM-DD')
})

export default { formatMoney, formatDate }
