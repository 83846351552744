const state = {
  users: []
}

const mutations = {
  SET_USERS(state, users) {
    state.users = users
  },
  ADD_USER(state, user) {
    state.users.push(user)
  },
  UPDATE_USER(state, updatedUser) {
    const index = state.users.findIndex(u => u.id === updatedUser.id)
    if (index !== -1) {
      state.users[index] = updatedUser
    }
  },
  DELETE_USER(state, userId) {
    state.users = state.users.filter(user => user.id !== userId)
  }
}

const actions = {
  fetchUsers({ commit }) {
    // Simulate an API call to fetch users
    const users = [
      { id: 1, name: 'John Doe' },
      { id: 2, name: 'Jane Smith' },
      { id: 3, name: 'Bob Johnson' }
    ]
    commit('SET_USERS', users)
  },
  addUser({ commit }, user) {
    // Simulate an API call to add a user
    // You can handle async actions and API calls here
    commit('ADD_USER', user)
  },
  updateUser({ commit }, updatedUser) {
    // Simulate an API call to update a user
    commit('UPDATE_USER', updatedUser)
  },
  deleteUser({ commit }, userId) {
    // Simulate an API call to delete a user
    commit('DELETE_USER', userId)
  }
}

const getters = {
  getUsers: state => state.users,
  getUserById: state => id => state.users.find(user => user.id === id)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
