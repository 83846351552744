import baseService from "@/services/base.service";

const baseUrl = "billing-information";

export const BillingInformationService = {
  getBillingInformation(params = {}) {
    return baseService.get(`${baseUrl}`, { params }).then((resp) => {
      return resp.data;
    });
  },

  getBankInformation(params = {}) {
    return baseService.get(`bank-information`, { params }).then((resp) => {
      return resp.data;
    });
  },

  getCoinInformation(params = {}) {
    return baseService.get(`coin-information`, { params }).then((resp) => {
      return resp.data;
    });
  },

  postBillingInformation(data = {}) {
    return baseService.post(`${baseUrl}`, data).then((resp) => {
      return resp.data;
    });
  },

  putBillingInformation(id, data = {}) {
    return baseService.put(`${baseUrl}/${id}`, data).then((resp) => {
      return resp.data;
    });
  },

  postBankInformation(data = {}) {
    return baseService.post(`bank-information`, data).then((resp) => {
      return resp.data;
    });
  },

  putBankInformation(id, data = {}) {
    return baseService.put(`bank-information/${id}`, data).then((resp) => {
      return resp.data;
    });
  },

  postCoinInformation(data = {}) {
    return baseService.post(`coin-information`, data).then((resp) => {
      return resp.data;
    });
  },

  putCoinInformation(id, data = {}) {
    return baseService.put(`coin-information/${id}`, data).then((resp) => {
      return resp.data;
    });
  },

  sendVerifyCode(data = {}) {
    return baseService.post(`send-otp`, data).then((resp) => {
      return resp.data;
    });
  },

  postWithdrawMoney(data = {}) {
    return baseService.post(`/withdraw-money`, data).then((resp) => {
      return resp.data;
    });
  },
};
