import {BillingInformationService} from "@/services";

const state = {
    billingInformation: [],
    bankInformation: {},
    coinInformation: {},
}

const mutations = {
    SET_BILLING_INFORMATION(state, billingInformation) {
        state.billingInformation = billingInformation
    },
    SET_BANK_INFORMATION(state, bankInformation) {
        state.bankInformation = bankInformation
    },
    SET_COIN_INFORMATION(state, coinInformation) {
        state.coinInformation = coinInformation
    },
}

const actions = {
    async fetchBillingInformation({ commit }, params = {}) {
        const res = await BillingInformationService.getBillingInformation(params)
        commit('SET_BILLING_INFORMATION', res.data)
        return res;
    },

    async fetchBankInformation({ commit }, params = {}) {
        const res = await BillingInformationService.getBankInformation(params)
        commit('SET_BANK_INFORMATION', res.data)
        return res;
    },

    async fetchCoinInformation({ commit }, params = {}) {
        const res = await BillingInformationService.getCoinInformation(params)
        commit('SET_COIN_INFORMATION', res.data)
        return res;
    },

    async putBillingInformation(_, params = {}) {
        const { id } = params;
        const res = await BillingInformationService.putBillingInformation(id, params)
        return res;
    },

    async postBillingInformation(_, params = {}) {
        const res = await BillingInformationService.postBillingInformation(params)
        return res;
    },

    async putBankInformation(_, params = {}) {
        const { id } = params;
        const res = await BillingInformationService.putBankInformation(id, params)
        return res;
    },

    async postBankInformation(_, params = {}) {
        const res = await BillingInformationService.postBankInformation(params)
        return res;
    },

    async putCoinInformation(_, params = {}) {
        const { id } = params;
        const res = await BillingInformationService.putCoinInformation(id, params)
        return res;
    },

    async postCoinInformation(_, params = {}) {
        const res = await BillingInformationService.postCoinInformation(params)
        return res;
    },

    async sendVerifyCode(_, params = {}) {
        const res = await BillingInformationService.sendVerifyCode(params)
        return res;
    },

    async postWithdrawMoney(_, params = {}) {
        const res = await BillingInformationService.postWithdrawMoney(params)
        return res;
    },
}

const getters = {
    getBillingInformation: state => state.billingInformation,
    getBankInformation: state => state.bankInformation,
    getCoinInformation: state => state.coinInformation,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
