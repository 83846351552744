import baseService from "@/services/base.service";

const baseUrl = "conversions";
export const ConversionsService = {
  list(params = {}) {
    return baseService.get(`${baseUrl}`, { params }).then((resp) => {
      return resp.data;
    });
  },
};
