export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const PayoutRoute = {
  path: "/payout",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "PayoutPage",
      component: Page("PayoutPage"),
    },
  ],
};

export default PayoutRoute;
