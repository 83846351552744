export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const MyTradingAccountRoute = {
  path: "/my-trading-account",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "MyTradingAccountPage",
      component: Page("MyTradingAccountPage"),
    },
  ],
};

export default MyTradingAccountRoute;
