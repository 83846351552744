// import { AuthGuard, ResolveGuard } from "@/guards";

export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const HomeRoute = {
  path: "/",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "HomePage",
      component: Page("HomePage"),
      // beforeEnter: ResolveGuard([AuthGuard]),
    },
  ],
};

export default HomeRoute;
