import store from "@/store";

export const LoginGuard = (to, from, next) => {
    if (store.getters["auth/isAuthenticated"]) {
        // next({name: "BrokerPage"});
        return;
    }

    next();
};
