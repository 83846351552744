import Vue from "vue";
import Vuex from "vuex";

// Modules
import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import broker from "@/store/modules/broker";
import post from "@/store/modules/post";
import affiliate from "@/store/modules/affiliate";
import crm from "@/store/modules/crm";
import modal from "@/store/modules/modal";
import userRegisteredBroker from "@/store/modules/user-registered-broker";
import billingInformation from "@/store/modules/billing-information";
import conversions from "@/store/modules/conversions";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    sidebarCollapsed: false,
    isLoading: false,
    requestPending: 0,
  },
  mutations: {
    setLoading(state, payload) {
      if (payload) {
        state.requestPending++;
        state.isLoading = true;
      } else {
        state.requestPending--;
        if (state.requestPending === 0) state.isLoading = false;
      }
    },
    setToggleSidebar(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isSidebarCollapsed: (state) => state.sidebarCollapsed,
  },
  modules: {
    auth,
    user,
    broker,
    post,
    affiliate,
    crm,
    modal,
    userRegisteredBroker,
    billingInformation,
    conversions
  }
})

export default store;
