<template>
  <router-view />
</template>

<script>

export default {
  name: "IndexPage",
};
</script>

<style lang="scss" scoped></style>
