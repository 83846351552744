import {CrmService} from "@/services";

const state = {
    topCampaigns: [],
    statistics: {},
    histories: [],
    withdrawMoney: [],
}

const mutations = {
    SET_TOP_CAMPAIGNS(state, topCampaigns) {
        state.topCampaigns = topCampaigns
    },
    SET_STATISTICS(state, statistics) {
        state.statistics = statistics
    },
    SET_HISTORIES(state, histories) {
        state.histories = histories
    },
    SET_WITHDRAW_MONEY(state, withdrawMoney) {
        state.withdrawMoney = withdrawMoney
    },
}

const actions = {
    async fetchTopCampaigns({commit, state}, params = {}) {
        const res = await CrmService.getTopCampaign({...state.params, ...params})
        commit('SET_TOP_CAMPAIGNS', res.data)
    },

    async fetchStatistics({commit, state}, params = {}) {
        const res = await CrmService.getStatistic({...state.params, ...params})
        commit('SET_STATISTICS', res.data)
    },

    async fetchHistories({commit, state}, params = {}) {
        const res = await CrmService.getHistories({...state.params, ...params})
        commit('SET_HISTORIES', res.data)
    },

    async fetchWithdrawMoney({commit, state}, params = {}) {
        const res = await CrmService.getWithdrawMoney({...state.params, ...params})
        commit('SET_WITHDRAW_MONEY', res.data)
    },

    async postWithdrawMoney(_, params = {}) {
        const res = await CrmService.postWithdrawMoney(params)
        return res;
    },
}

const getters = {
    getTopCampaigns: state => state.topCampaigns,
    getStatistics: state => state.statistics,
    getHistories: state => state.histories,
    getWithdrawMoney: state => state.withdrawMoney,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
