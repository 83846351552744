import {ConversionsService} from "@/services";

const state = {
    conversions: []
}

const mutations = {
    SET_CONVERSIONS(state, conversions) {
        state.conversions = conversions
    },
}

const actions = {
    async fetchConversions({commit}, params = {}) {
        const res = await ConversionsService.list(params);
        commit('SET_CONVERSIONS', res.data)
        return res;
    },
}

const getters = {
    getConversions: state => state.conversions,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
