import * as rules from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required_custom", { ...rules.required });
extend("email", { ...rules.email });
extend("is", { ...rules.is });
extend("between", { ...rules.between });
extend("money", {
    ...rules.between,
    message: 'You do not have sufficient funds in your Balace Wallet.',
});
