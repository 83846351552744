import {LoginGuard, ResolveGuard} from "@/guards";

export function Page(path) {
    return () =>
        import(
            /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
            );
}

const AuthRoute = {
    path: "/auth",
    component: Page("IndexPage"),
    children: [
        {
            path: "/auth/login",
            name: "LoginPage",
            meta: {layout: "auth"},
            component: Page("LoginPage"),
            beforeEnter: ResolveGuard([LoginGuard]),
        },

        {
            path: "/auth/register",
            name: "RegisterPage",
            meta: {layout: "auth"},
            component: Page("RegisterPage"),
            beforeEnter: ResolveGuard([LoginGuard]),
        },
    ],
};

export default AuthRoute;
