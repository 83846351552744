import instance from "@/services/axiosConfig";

const baseService = {
    get: (url, config = {}) => {
        return instance.get(url, config);
    },
    post: (url, data, config = {}) => {
        return instance.post(url, data, config)
    },
    put: (url, data, config = {}) => {
        return instance.put(url, data, config)
    },
    delete: (url, config = {}) => {
        return instance.delete(url, config)
    },
    patch: (url, data, config = {}) => {
        return instance.patch(url, data, config)
    },
};

export default baseService;
