import IndexPage from "@/views/pages/IndexPage";
import BadRequest from "@/views/pages/errors/BadRequest.vue";
import Unauthorized from "@/views/pages/errors/Unauthorized.vue";
import Forbidden from "@/views/pages/errors/Forbidden.vue";
import NotFound from "@/views/pages/errors/NotFound.vue";
import {AuthGuard, CallbackGuard, LoginGuard, ResolveGuard} from "@/guards";
import HomeRoute from "@/router/routes/home";
import BrokerRoute from "@/router/routes/broker";
import BonusHunterRoute from "@/router/routes/bonus-hunter";
import PostRoute from "@/router/routes/post";
import AuthRoute from "@/router/routes/auth";
import CRMRoute from "@/router/routes/crm";
import ProfileRoute from "@/router/routes/profile";
import MyTradingAccountRoute from "@/router/routes/myTradingAccount";
import SuccessMarketSendMailRoute from "@/router/routes/successMarketSendMailRoute";
import PayoutRoute from "@/router/routes/payout";

// Modules

export function Page(path) {
    return () =>
        import(
            /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
            );
}

const routes = [
    {
        path: "",
        component: IndexPage,
        beforeEnter: ResolveGuard([LoginGuard]),
        children: [
            {...AuthRoute},
        ],
    },

    {
        path: "",
        // name: "IndexPage",
        component: IndexPage,
        beforeEnter: ResolveGuard([AuthGuard]),
        children: [
            {...CRMRoute},
            {...HomeRoute},
            {...BrokerRoute},
            {...BonusHunterRoute},
            {...PostRoute},
            {...ProfileRoute},
            {...MyTradingAccountRoute},
            {...SuccessMarketSendMailRoute},
            {...PayoutRoute}
        ],
    },

    {
        path: "/google-callback",
        beforeEnter: ResolveGuard([CallbackGuard]),
    },

    // Other routes
    {
        path: "/bad-request",
        name: "BadRequest",
        component: BadRequest,
    },
    {
        path: "/unauthorized",
        name: "Unauthorized",
        component: Unauthorized,
    },
    {
        path: "/forbidden",
        name: "Forbidden",
        component: Forbidden,
    },
    {
        path: "/not-found",
        name: "NotFound",
        component: NotFound,
    },
    // Other routes

    {
        path: "*",
        redirect: '/',
    },
];

export default routes;
