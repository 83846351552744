export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const CRMRoute = {
  path: "/",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "CRMPage",
      component: Page("CRMPage"),
    },
  ],
};

export default CRMRoute;
