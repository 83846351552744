export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const ProfileRoute = {
  path: "/profile",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "ProfilePage",
      component: Page("ProfilePage"),
    },
  ],
};

export default ProfileRoute;
