import baseService from "@/services/base.service";

const baseUrl = "user-registered-broker";

export const UserRegisteredBrokerService = {
  getList(params = {}) {
    return baseService.get(`${baseUrl}`, { params }).then((resp) => {
      return resp.data;
    });
  },

  getItem(id, config = {}) {
    return baseService.get(`${baseUrl}/${id}?includes=detail_total`, config).then((resp) => {
      return resp.data;
    });
  },

  postUserRegisteredBroker(data = {}) {
    return baseService.post(`${baseUrl}`, data).then((resp) => {
      return resp.data;
    });
  },

  postSendMail(id, config = {}) {
    return baseService.post(`${baseUrl}/${id}/send-mail`, config).then((resp) => {
      return resp.data;
    });
  }
};
