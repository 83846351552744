import {HttpStatusCode, LOCAL_STORAGE} from '@/enums';
import router from "@/router";

export const handleSuccessResponse = (response) => {
    const {status, data} = response;

    switch (status) {
        case HttpStatusCode.OK:
            // handleOkResponse(data);
            break;
        case HttpStatusCode.CREATED:
            handleCreatedResponse(data);
            break;
        case HttpStatusCode.NO_CONTENT:
            handleNoContentResponse();
            break;
        default:
            handleOtherSuccessResponse(data);
            break;
    }
};

export const handleErrorResponse = (error) => {
    const {response} = error;

    if (response) {
        const {status, data} = response;

        switch (status) {
            case HttpStatusCode.BAD_REQUEST:
                // handleBadRequest(router, data);
                break;
            case HttpStatusCode.UNAUTHORIZED:
                router.push({name: 'LoginPage'});
                localStorage.removeItem(LOCAL_STORAGE.USER_TOKEN);
                break;
            case HttpStatusCode.FORBIDDEN:
                handleForbidden(router, data);
                break;
            case HttpStatusCode.NOT_FOUND:
                handleNotFound(router, data);
                break;
            default:
                handleOtherErrorResponse(data);
                break;
        }
    } else {
        handleNetworkError(error);
    }
};

// Success response handlers
// const handleOkResponse = (data) => {
//     console.log('Success:', data);
// };

const handleCreatedResponse = (data) => {
    console.log('Created:', data);
};

const handleNoContentResponse = () => {
    console.log('No Content');
};

const handleOtherSuccessResponse = (data) => {
    console.log('Success:', data);
};

// Error response handlers
// const handleBadRequest = (router, data) => {
//   router.push({ name: 'BadRequest' });
//   console.log('Bad Request:', data);
// };

const handleForbidden = (router, data) => {
    router.push({name: 'Forbidden'});
    console.log('Forbidden:', data);
};

const handleNotFound = (router, data) => {
    router.push({name: 'NotFound'});
    console.log('Not Found:', data);
};

const handleOtherErrorResponse = (data) => {
    console.log('Error:', data);
};

const handleNetworkError = (error) => {
    console.log('An error occurred:', error.message);
};
