<template>
  <div
      v-if="isModalVisible"
      class="modal modal-dark fade show d-block"
      :class="modalComponent"
  >
    <div
        class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable modal-fullscreen-md-down"
        :class="{ unsetWidth: modalComponent === 'MarketJoinModal' }"
    >
      <div id="content" class="modal-content">
        <component :is="modalComponent" :data="modalData"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import MarketJoinModal from "@/components/modal/MarketJoinModal";
import AddCoinWalletModal from "@/components/modal/AddCoinWalletModal";
import AddBankWalletModal from "@/components/modal/AddBankWalletModal";
import SuccessMarketJoinModal from "@/components/modal/SuccessMarketJoinModal";
import WithdrawCoinModal from "@/components/modal/WithdrawCoinModal.vue";
import WithdrawBankModal from "@/components/modal/WithdrawBankModal.vue";

export default {
  name: 'BaseModal',

  components: {
    MarketJoinModal,
    AddCoinWalletModal,
    AddBankWalletModal,
    SuccessMarketJoinModal,
    WithdrawCoinModal,
    WithdrawBankModal
  },

  computed: {
    ...mapGetters('modal', ['isModalVisible', 'modalComponent', 'modalData'])
  },

  methods: {
    ...mapActions('modal', ['openModal', 'closeModal'])
  },
}
</script>
<style lang="scss" scoped>
.modal-dialog {
  width: fit-content;
}

.unsetWidth {
  width: unset !important;
}
</style>
