import Vue from "vue";
import { get, set, cloneDeep } from "lodash-es";

Object.defineProperty(Vue.prototype, "$_get", {
  get: function () {
    return get;
  },
});

Object.defineProperty(Vue.prototype, "$_set", {
  get: function () {
    return set;
  },
});

Object.defineProperty(Vue.prototype, "$_cloneDeep", {
  get: function () {
    return cloneDeep;
  },
});


// Examples
// this.$_get(object, "nested.key");
// this.$_set(object, "nested.key", value);
// this.$_cloneDeep(object);
