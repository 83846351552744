const state = {
  isModalVisible: false,
  modalComponent: null,
  modalData: null
}

const mutations = {
  SHOW_MODAL(state, { component, data }) {
    state.isModalVisible = true;
    state.modalComponent = component;
    state.modalData = data;
  },
  HIDE_MODAL(state) {
    state.isModalVisible = false;
    state.modalComponent = null;
  }
}

const actions = {
  openModal({ commit }, { component, data }) {
    document.body.classList.toggle('modal-open');
    commit('SHOW_MODAL', { component, data });
  },
  closeModal({ commit }) {
    document.body.classList.toggle('modal-open');
    commit('HIDE_MODAL');
  }
}

const getters = {
  isModalVisible: state => state.isModalVisible,
  modalComponent: state => state.modalComponent,
  modalData: state => state.modalData
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
