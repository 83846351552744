<template>
  <div>
    <div id="app">
      <transition>
        <div class="spinner" :class="{ show: isLoading }">
          <div class="semipolar-spinner">
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
          </div>
        </div>
      </transition>
      <component :is="layout" v-if="layout"/>
    </div>

    <BaseModal />

    <div v-if="isModalVisible" class="modal-backdrop fade show">
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import BaseModal from "@/components/modal/BaseModal";

export default {
  name: 'App',

  components: {
    default: () =>
        import(/* webpackChunkName: "default" */ "@/views/layouts/Default.vue"),
    auth: () =>
        import(/* webpackChunkName: "default" */ "@/views/layouts/Auth.vue"),
    BaseModal,
  },

  computed: {
    ...mapGetters(["isLoading"]),
    ...mapGetters('modal', ['isModalVisible']),

    layout() {
      return this.$route.meta["layout"] || "default";
    },
  },

  methods: {
    ...mapActions('modal', ['closeModal'])
  },
}
</script>

<style>
.spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: none;
  opacity: 0;
  transition: all 400ms ease-in-out;
}

.spinner.show {
  display: flex;
  opacity: 1;
  transition: all 400ms ease-in-out;
}

.semipolar-spinner, .semipolar-spinner * {
  box-sizing: border-box;
}

.semipolar-spinner {
  height: 65px;
  width: 65px;
  position: relative;
}

.semipolar-spinner .ring {
  border-radius: 50%;
  position: absolute;
  border: calc(65px * 0.05) solid transparent;
  border-top-color: #F8AF2F;
  border-left-color: #F8AF2F;
  animation: semipolar-spinner-animation 400ms infinite;
}

.semipolar-spinner .ring:nth-child(1) {
  height: calc(65px - 65px * 0.2 * 0);
  width: calc(65px - 65px * 0.2 * 0);
  top: calc(65px * 0.1 * 0);
  left: calc(65px * 0.1 * 0);
  animation-delay: calc(400ms * 0.1 * 4);
  z-index: 5;
}

.semipolar-spinner .ring:nth-child(2) {
  height: calc(65px - 65px * 0.2 * 1);
  width: calc(65px - 65px * 0.2 * 1);
  top: calc(65px * 0.1 * 1);
  left: calc(65px * 0.1 * 1);
  animation-delay: calc(400ms * 0.1 * 3);
  z-index: 4;
}

.semipolar-spinner .ring:nth-child(3) {
  height: calc(65px - 65px * 0.2 * 2);
  width: calc(65px - 65px * 0.2 * 2);
  top: calc(65px * 0.1 * 2);
  left: calc(65px * 0.1 * 2);
  animation-delay: calc(400ms * 0.1 * 2);
  z-index: 3;
}

.semipolar-spinner .ring:nth-child(4) {
  height: calc(65px - 65px * 0.2 * 3);
  width: calc(65px - 65px * 0.2 * 3);
  top: calc(65px * 0.1 * 3);
  left: calc(65px * 0.1 * 3);
  animation-delay: calc(400ms * 0.1 * 1);
  z-index: 2;
}

.semipolar-spinner .ring:nth-child(5) {
  height: calc(65px - 65px * 0.2 * 4);
  width: calc(65px - 65px * 0.2 * 4);
  top: calc(65px * 0.1 * 4);
  left: calc(65px * 0.1 * 4);
  animation-delay: calc(400ms * 0.1 * 0);
  z-index: 1;
}

@keyframes semipolar-spinner-animation {
  50% {
    transform: rotate(360deg) scale(0.7);
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}
</style>
