export function Page(path) {
  return () =>
      import(
          /* webpackChunkName: "[request]" */ `@/views/pages/${path}.vue`
          );
}

const successMarketSendMailRoute = {
  path: "/send-mail/:id/:code",
  component: Page("IndexPage"),
  children: [
    {
      path: "",
      name: "SuccessMarketSendMailPage",
      component: Page("SuccessMarketSendMailPage"),
    },
  ],
};

export default successMarketSendMailRoute;
