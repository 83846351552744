import baseService from "@/services/base.service";

const baseUrl = "affiliate";
export const AffiliateService = {
  countAffiliate(params = {}) {
    return baseService.get(`${baseUrl}/update-count-click`, { params }).then((resp) => {
      return resp.data;
    });
  },
};
